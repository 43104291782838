import Vue from "vue";
import VueRouter from "vue-router";

import { Message } from 'element-ui'

const Login = () => import("../views/LoginView.vue");
const User = () => import("../views/UserView.vue");
const Admin = () => import("../views/AdminView.vue");
const MessageView = () => import("../views/MessageView.vue");
const Manage = () => import("../views/ManageView.vue");
const Control = () => import("../views/ControlView.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: "active",
  routes: [
    { path: "/", redirect: "/login" },
    { path: "/login", component: Login },
    { path: "/user", component: User },
    {
      path: "/admin", component: Admin, children: [
        { path: "", redirect: "message" },
        { path: "message", component: MessageView },
        { path: "manage", component: Manage },
        { path: "control", component: Control },
      ]
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  let valid = true;
  if (token == null) {
    valid = false;
  }

  console.log(to.path);

  if ((to.path.slice(0, 6) === "/admin" || to.path === "/manage") && !valid) {
    next("/login");
    Message({
      showClose: true,
      message: '未检测到登录信息，请先登录',
      type: 'warning'
    });
  }
  next();
});

export default router;
