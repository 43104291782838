import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    socket: null,
    userList: [],
    userid: "0",
    nickname: "未选择设备",
  },
  getters: {
    userChat(state) {
      return state.userList.find((item) => {
        return item.deviceID === state.userid;
      })
    }
  },
  mutations: {
    setSocket(state, socket) {
      state.socket = socket;
    },
    setUserid(state, userid) {
      // if (state.userid !== "0" && state.socket !== null) {
      //   state.socket.onSend(JSON.stringify({ userid: state.userid, model: "disconnect" }))
      // }
      state.userid = userid;
    },
    setName(state, nickname) {
      state.nickname = nickname;
    },
    setUserList(state, userList) {
      console.log(userList);
      state.userList = JSON.parse(JSON.stringify(userList));
    },
    setMessage(state, info) {
      const { message, deviceID } = info
      state.userList.forEach((item) => {
        if (item.deviceID === deviceID) {
          message.id = item.message.length;
          item.message.push(message);
          if (item.message.length > 2000) {
            item.message = item.message.slice(-2000);
          }
        }
      })
    },
    clearMessage(state, userid) {
      state.userList.forEach((item) => {
        if (item.deviceID === userid) {
          item.message = [];
        }
      })
    },
    addUserList(state, user) {
      user.message = [];
      state.userList.push(user);
    }
  },
  actions: {},
  modules: {},
});
