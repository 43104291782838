import "./assets/css/style.less";
import "element-ui/lib/theme-chalk/index.css";
import Vue from "vue";
import router from "./router";
import store from "./store";

import http from "./utils/request";
Vue.prototype.$http = http;

import ElementUI from "element-ui";
Vue.use(ElementUI);

import {
  message
} from './utils/message';
Vue.prototype.$message = message;

new Vue({
  router,
  store,
  render: (h) => h("router-view"),
}).$mount("#app");
